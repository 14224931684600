/** @format */

p,
h1 {
  margin: 0;
}
nav {
  padding: 0.94rem;
}
nav .heading {
  flex: 0.7;
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.PricingContainer {
  flex: 1;
}
.pricing p,
.upgrade p {
  color: var(--White, #fafafc);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.pricing,
.upgrade {
  padding: 0.5rem 0.625rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.pricing:hover {
  background: #3c93f7;
}
.upgrade:hover {
  background: #ffb10a;
}

.pricing-header-right{
  justify-content: end;
}
