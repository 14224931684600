.buyer {
    display: flex;
    gap: .5rem;
    align-items: center;
    background-color: white;
    border-radius: .04rem;
    height:100%;
}
   .edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #f0f0f0;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .edit-icon-svg {
    width: 20px;
    height: 20px;
    fill: #333;
  }
  
  .edit-icon:hover .edit-icon-svg {
    fill: #007bff; /* Add hover effect */
  }
  

.buyer-info { 

    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 20rem;
    height: 99%;
    background-color: #ffffff;
    border: 1px solid #d0cbcb;
    border-radius: 0.5rem;
    overflow-y: auto; 
    padding: 1rem; 
    display: flex;
    flex-direction: column;
    position: relative;
}

.buyer-info::-webkit-scrollbar {
    width: 3px; 
}

.buyer-profile {
    width: 3em;
    height: 3em;
    border: .1rem solid #d0cbcb;
    margin-top: 1rem;
    border-radius: 50%;
    margin-left: 1rem;
    flex-shrink: 0; 
}

.buyer-name {
    margin-left: 1rem;
}

.buyer-email,
.buyer-phone,
.buyer-address {
    display: flex;
    margin-top: 0.5rem;
    margin-left: 1rem;
    flex-shrink: 0; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    white-space: normal; /* Allows the content to wrap as needed */
}

.buyer-email-icon,
.buyer-phone-icon,
.buyer-address-icon {
    width: 24px;  
    height: 24px; 
    margin-right: 0.5rem;
}

.lead-status-dropdown {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
  }
  
  .lead-status-dropdown label {
    font-weight: bold;
    margin-bottom: 8px;
    width: 100%;
  }
  
  .lead-status-dropdown select {
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .lead-status-dropdown select:focus {
    outline: none;
    border-color: none;
  }
  .add-notes-buyer-container {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
  }
  
  .add-notes-buyer-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .notes-textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical; 
  }
  
  .notes-textarea:focus {
    outline: none;
    border-color: black;                        
  }
  
  .save-notes-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  
.lead-stage-dropdown {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
}

.lead-stage-dropdown label {
  font-weight: bold;
  margin-bottom: 8px;
  width: 100%;
}

.lead-stage-dropdown select {
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
}

.lead-stage-dropdown select:focus {
  outline: none;
  border-color: none;
}
/* second column */
.buyer-second-row {
  padding: 1rem;
  width: 70%;
  height: 99%;
  background-color: #ffffff;
  border: 1px solid #d0cbcb;
  border-radius: 0.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.buyer-leads, .buyer-leads-email {
  width: 100%;
  max-height: 100%; /* Allow full height of the container */
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.buyer-message {
  width: 100%;
  min-height: fit-content;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
}
.buyer-email-content {
  width: 100%;
  min-height: fit-content;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
}
.view-action-plan{
  cursor: pointer;
  color: #007bff;
}
.view-action-plan:hover{
  text-decoration: underline;
}




/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 10rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content label {
    margin-bottom: 10px;
  }
  
  .modal-content input {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 20rem;
  }
  
  .modal-content button {
    padding: 10px;
    margin-right: 10px;
    background-color: #333;
    color: #ffffff;
    border: .05rem solid white;
    margin-top: .5rem;
  }
  
.buyer-info, .buyer-leads, .buyer-message, .buyer-note {
    max-width: 100%;
}
@media (max-width: 1010px) {
  .buyer {
    flex-direction: column; 
    gap: 1rem; /* Increased for better spacing on smaller screens */
  }

  .buyer-info {
    margin: 0 auto;
    width: 90%; /* Slightly reduced for better scaling */
    height: auto; /* Allow auto-height for flexibility */
    padding: 1rem; 
  }

  .buyer-profile {
    margin: 1rem auto 0 auto;
    width: 10vw; /* Use viewport width for scaling */
    height: 10vw; 
  }

  .buyer-name,
  .buyer-email,
  .buyer-phone,
  .buyer-address {
    text-align: center; 
  }

  .lead-status-dropdown,
  .add-notes-container {
    width: 95%;
    margin: 1rem auto;
  }

  .notes-textarea {
    height: 120px; 
  }

  .buyer-leads {
    width: 95%;
    height: auto; 
    margin: 1rem auto;
  }

  .buyer-message,
  .buyer-note-view {
    width: 95%;
    margin: 1rem auto;
    padding: 1rem;
  }

  .edit-icon {
    top: 8px;
    right: 8px;
    padding: 4px; 
  }

  .edit-icon-svg {
    width: 18px;
    height: 18px; 
  }
}

@media (max-width: 480px) {
  .buyer-info {
    padding: 0.8rem; /* Reduced for better fit on smaller screens */
    width: 95%;
  }

  .buyer-profile {
    width: 12vw; /* Scales based on viewport width */
    height: 12vw; 
  }

  .lead-status-dropdown select {
    width: 100%; 
  }

  .save-notes-button {
    width: 100%;
    font-size: 14px; 
    padding: 10px;
  }

  .buyer-message,
  .buyer-note-view {
    padding: 0.8rem;
    font-size: 14px; 
  }

  .notes-textarea {
    height: 100px; 
  }
}

@media (max-width: 768px) {
  .buyer {
    flex-direction: column;
    gap: 2rem;
  }

  .buyer-info {
    margin: 0 auto;
    width: 90%;
    height: auto; 
  }

  .buyer-profile {
    margin: 0 auto;
    width: 10vw; /* Scalable width */
    height: 10vw;
  }

  .buyer-name,
  .buyer-email,
  .buyer-phone,
  .buyer-address {
    text-align: center; 
  }

  .lead-status-dropdown,
  .add-notes-container {
    width: 90%;
    margin: 1rem auto;
  }

  .notes-textarea {
    height: 120px; 
  }

  .buyer-leads {
    width: 90%;
    margin: 1rem auto;
    height: auto;
  }

  .buyer-message,
  .buyer-note {
    width: 90%;
    height: auto;
  }
}


