/**
 * body {
 *     background-color: #f5f5f5 !important;
 * }
 *
 * @format
 */
/* animation */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInLeft {
  animation: slideInFromLeft 3s ease-out;
  animation-fill-mode: forwards;
}

.animatedText {
  animation: slideInFromRight 3s ease-out;
  animation-delay: 0.5s;
  animation-iteration-count: 2;
  animation-fill-mode: forwards;
}

.animatedText2 {
  animation: slideInFromLeft 3s ease-out;
  animation-delay: 5s;
  animation-iteration-count: 2;
  animation-fill-mode: forwards;
}

.template1 .pageText > p {
  color: rgb(33, 33, 33);
}

.template1 .navbar-nav {
  color: rgb(33, 33, 33);
}

.template1 .sec1 {
  width: 60%;
  display: grid;
  align-items: center;
  background-size: cover;
  position: relative;
  font-family: Tenor Sans, sans-serif;
  
}
.template1 .sec1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.template1 .sec1 iframe {
  box-sizing: border-box;
}

.template1 .sec1 .backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.template1 .sec2 .col-md-4 {
  align-content: start;
}

.template1 .sec1 > .Sec1Container {
  padding: 1rem;
  position: absolute;
  left: 3%;
  top: 50%;
}

.template1 .sec1 > .Sec1Container > * .Heading {
  font-family: Syncopate;
  font-size: 3rem;
  font-size: clamp(1.5rem, 3vw, 3rem);
  font-weight: 700;
  /* line-height: 3.1231rem; */
  text-align: left;
  /* color: #000000; */
}

/* .template1 .sec1 > .Sec1Container > * .Heading * {
  font-family: Syncopate;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.1231rem;
  text-align: left;
  color: #000000;
} */


.template1 .sec1 > .Sec1Container > h2 {
  font-family: Syncopate;
  font-size: clamp(1.5rem, 3vw, 3rem);
  font-weight: 700;
  line-height: 3.1231rem;
  text-align: left;
  color: #000000;
}

.template1 .sec1 > .Sec1Container > p {
  font-family: Montserrat,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  text-align: left;
  color: #000000;
  width: min(36.125rem, 95%);
}

.template1 .sec1 > .container > .btn {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid white;
  border-radius: 0;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 500;
}

.template1 .sec2 {
  color: black;
  margin-top: 5rem;
  font-family: Tenor Sans, sans-serif;
  font-size: 1.2rem;
}

.template1 .sec2 .card_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-family: Tenor Sans, sans-serif;
}

.template1 .sec2 h2 {
  text-align: right;
  width: 100%;
  margin-right: 0;
  font-family: Tenor Sans, sans-serif;
}

.template1 .sec2 p {
  text-align: right;
  width: 66.67%;
  margin-left: auto;
  margin-right: 0;
  color: black;
  font-family: Montserrat,sans-serif;
  font-size: 1rem;
}

.template1 .sec2 .text > p {
  text-align: right;
  color: black;
  font-family: Montserrat,sans-serif;
  font-size: 1rem;
}

.template1 .sec2 .row {
  margin-top: -5rem;
}

.template1 .sec2 .card {
  background: transparent;
  border: 0px;
  border-radius: 0px;
}

.template1 {
  background: white;
}

.template1 .sec3 .backgroundImage {
  width: 100%;
  margin-left: 50px;
  max-height: 80vh;
}

@media (max-width: 768px) {
  .template1 .sec3 .backgroundImage {
    margin-left: 0px;
  }
}

.template1 .row {
  padding-top: 6rem;
}

.template1 .sec3 .col-md-5 {
  align-content: start;
  /* padding: 0rem 5rem; */
}

.template1 .sec3 h2 {
  font-size: 38px;
  text-transform: uppercase;
}

.template1 .sec4 {
  margin-top: 2rem;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.sec4 .container {
  width: min(50rem, 85%);
}

.template1 .sec4 p {
  text-align: center;
  font-size: 20px;
  margin-top: 1rem;
}

.template1 .sec4 h2 {
  text-align: center;
  font-size: 40px;
  margin-top: 0;
}

.template1 .sec2 .card-img-top {
  width: 416px;
  filter: brightness(0.6);
}

.template1 .sec4 .col-md-4 p {
  margin-top: -3px;
}
.template1 .sec5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 2rem;
  background-color: var(--primary);
  width: 100%;
}

.template1 .sec5 .img:hover {
  transform: scale(1.2);
}
.template1 .sec5 h2 {
  text-align: center;
  padding: 2%;
  font-size: 40px;
  margin-top: 2rem;
  margin-bottom: -2rem;
  text-transform: uppercase;
  color: white;
}

.template1 .sec5 .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.template1 .sec5 .col-md-4 {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .template1 .sec5 .col-md-2 {
  display: flex;
  justify-content: center; 
  flex-direction: column; 
  align-items: center; 
} */

.template1 .sec5 .row h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}

.template1 .sec5 .row p {
  text-align: center;
  font-size: 16px;
  max-width: 80%;
  margin: auto;
  color: var(--primary);
}

.template1 .sec5 .img {
  width: 70px;
  height: 70px;
  background: #241b1c;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 1rem;
  color: white;
  display: grid;
  justify-content: center;
  font-size: 36px;
  transition: transform 0.3s ease;
  padding: 15px;
}

.template1 .sec5 .img > img {
  width: 40px;
}

.template1 .sec5 .desc {
  text-align: center;
  font-size: 20px;
  margin-top: 5rem;
  margin-bottom: 3rem;
  color: white; /* White text color */
  font-family: Montserrat,sans-serif;
}

.template1 .sec4 {
  background-repeat: no-repeat;
  background-size: cover;
}
.template1 .sec9 {
  background-repeat: no-repeat;
  background-size: cover;
}
.template1 .sec6 {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000;
  color: #ffffff;
}
.template1 .sec6 .text {
  text-align: end;
}

.template1 .sec6 .text p {
  text-align: end;
}

.template1 .sec6 .text a {
  color: #fff;
}

.template1 .sec6 .card {
  border: 0px;
}

.template1 .sec6 .card-price {
  font-size: 28px;
}

.template1 .sec6 .card-title {
  font-size: 22px;
}

.template1 .sec6 .card-text {
  font-size: 18px;
}

.template1 .sec6 .row {
  padding: unset;
}

.template1 .sec8 {
  padding-top: 5rem;
}

.template1 .sec8 .row {
  margin-top: -5rem;
}

.template1 .sec8 .row .card {
  border: 0px;
}

.template1 .sec8 .card-title {
  font-size: 18px;
}

.template1 .sec8 .card-text {
  font-size: 16px;
}

.template1 .sec9 {
  margin-top: 1rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  background-position: center;
}

.template1 .sec9 h2 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.template1 .sec9 p {
  text-align: center;
  margin-bottom: 3rem;
  margin: auto;
  padding-bottom: 3rem;
  min-width: 20rem;
  max-width: 60rem;  
}

/* .template1 .sec9 a { */
  /* margin-top: 1rem;
  background-color: var(--primary);
  color: white;
  padding: 15px 25px;
  text-decoration: none; */
/* } */

.template1 .sec2 {
  background: linear-gradient(white 50%, var(--primary) 50%);
}
.ImageSection {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 80vh;
}
.template1 .sec3 {
  background: linear-gradient(var(--primary) 100%, white 10%);
}
 .template1 > .sec3{
    width: min(120rem,100%);
    margin: 0 auto;
    /* margin-bottom: 7rem; */
  }
  /* .template1 > .sec3 > .row > .image_video_section{
   margin-bottom:-4rem;
  } */
  /* .template1 > .sec3 > .row > .text_sectio{
   margin-top:4rem;
  } */

@media (max-width: 768px) {
  .template1 .sec1 {
    flex-direction: column-reverse;
    /* height: fit-content !important; */
    /* padding-top: 18rem; */
    /* padding-bottom: 2rem; */
  }

  .template1 .sec1 > .container {
    max-width: 100vw;
    height: fit-content;
    margin-left: 0rem !important;
    padding: 0px 1rem;
    height: fit-content;
    position: static !important;
    margin-top: 1.9rem;
    margin-left: auto;
  }
  .template1 .sec1 > .Sec1Container > p {
    text-align: left;
  }

  .template1 .sec1 > .Sec1Container > h2 {
    /* width: 80%; */
    text-align: right;
    margin-left: auto;
  }

  .template1 .sec1 > .container > a {
    color: black !important;
    border: 1px solid black !important;
  }

  .template1 .sec2 p {
    width: 100%;
    text-align: center !important;
    font-size: 16px;
    margin-top: 1rem;
  }

  .template1 .sec2 > .container > .row {
    background: var(--primary) !important;
    margin-top: 2rem;
    padding-top: 1rem;
  }

  .template1 .sec2 > .container > .row > .col-md-4 {
    margin-left: 0rem !important;
    padding: 0px 0rem;
  }

  .template1 .sec2 > .container > .row > .col-md-4 > .card > img {
    margin: auto !important;
  }

  .template1 .sec2 .card-body {
    padding: 3rem 2rem;
  }
 .template1 > .sec3{
    width: min(120rem,100%);
    margin: 0 auto;
    margin-bottom: 7rem;
  }
  /* .template1 > .sec3 > .row > .image_video_section{
   margin-bottom:-4rem;
  } */
  .template1 .sec3 > .row {
    margin-top: 0rem;
    padding-top: 7rem;
  }

  .template1 .sec3 .col-md-7 > img {
    width: auto !important;
    height: auto !important;
  }
  .template1 .sec3 .col-md-5 {
    padding: 3rem 1rem;
  }
  .template1 .sec3 .col-md-5 .container {
    padding: 0px 1rem;
  }

  .template1 .sec3 .col-md-5 h2 {
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .template1 .sec3 .col-md-5 p {
    font-size: 16px;
  }

  .template1 .sec3 {
    background: linear-gradient(var(--primary) 100%, white 10%);
  }

  .template1 .sec4 {
    margin-top: 0rem;
    padding-top: 5rem;
  }

  .template1 .sec4 h2 {
    font-size: 30px;
    margin-bottom: 2rem;
  }

  .template1 .sec4 p {
    font-size: 16px;
  }

  .template1 .sec5 .row {
    grid-template-columns: repeat(2, 1fr);
  }
  /* .template1 .sec5 .col-md-4 {
    flex: 1 1 calc(50% - 1rem); 
  } */

  .template1 .sec5 h2 {
    text-align: right;
    margin-bottom: 4rem;
  }
  .template1 .sec5 {
    padding: 2rem 2rem;
    margin: 0rem;
  }
  .template1 .sec5 .row {
    margin-top: 0rem;
    padding: 0rem;
  }
  .template1 .sec5 .row .col-md-4 {
    /* padding: 0rem; */
    margin-bottom: 3rem;
  }

  .template1 .sec5 .row p {
    max-width: 100%;
  }

  .template1 .sec5 .desc {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }

  .template1 .footer {
    height: fit-content !important;
    padding-bottom: 3rem;
  }

  .template1 .footer .col-md-2 {
    justify-content: start !important;
    padding: 0px 2rem;
    margin-bottom: 2rem;
  }
  .template1 .footer .col-md-4 {
    margin-bottom: 2rem;
  }

  .template1 .footer a {
    padding: 0px;
    margin: 0px;
    margin-top: 0px !important;
  }
}

.template1 .idxspacer {
  margin-top: 2rem;
}

.news_letter {
  width: min(120rem, 100%);
  margin: 0 auto;
  position: relative;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin-top: 0;
  font-family: Montserrat,sans-serif;
}

.news_letter > .wrap_for_news_letter {
  width: min(60rem, 80%);
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 200px;
  margin-top: 0;
  padding-top: 0;
}

.news_letter > .wrap_for_news_letter > .heading {
  font-family: Tenor Sans, sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 33.31px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 1.5rem;
  width: 100%;
  margin-left: 0;
}

.news_letter > .wrap_for_news_letter > .news_letter_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 1rem;
  width: 100%;
}

.news_letter > .wrap_for_news_letter > .news_letter_box > .input_box_name,
.news_letter > .wrap_for_news_letter > .news_letter_box > .input_box_email {
  outline: none;
  border: none;
  border-left: 1px solid #888b97;
  padding: 1.2rem;
  width: calc(50% - 0.5rem);
  margin-left: 0;
}

.news_letter > .wrap_for_news_letter > .news_letter_box > .submit_button {
  font-family: "Montserrat,sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.24rem;
  text-align: center;
  background-color: var(--primary);
  color: #ffff;
  border: none;
  outline: none;
  padding: 0.65rem;
  border: 1px solid white;
  cursor: pointer;
  width: 150px;
}

.cityCard {
  background-color: var(--primary);
  width: min(120rem, 100%);
  margin: 0 auto;
  padding: 2rem 0.5rem;
}
.cityCard > .CityCardsWrapper {
  width: min(90rem, 95%);
  margin: 0 auto;
}
.cityCard > .CityCardsWrapper > .heading {
  color: #ffffff;
  font-family: Tenor Sans, sans-serif;
  font-size: 3rem;
  font-size: clamp(2rem, 3vw, 3rem);
  font-weight: 700;
  line-height: 3.1231rem;
  text-align: center;
}
/* .card-container {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 250px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 30px; 
  padding: 20px 0;
} */
.sec4 {
  position: relative;
  color: #fff;
}

.sec4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.sec4 > * {
  position: relative;
  z-index: 2;
}

.card-container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 2;
}

.card-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 864px) {
  /* .template1 .sec1::before { */
    /* background: none; */
  /* } */
  .template1 .sec1 .Sec1Container > .subheading {
    color: black;
  }
  .template1 .sec1 .Sec1Container > * .Heading {
    color: black !important;
  }
}

/* Media query for screens up to 926px wide */
@media screen and (max-width: 926px) {
  .news_letter > .wrap_for_news_letter {
    width: min(90%, 100%);
    padding-block: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .news_letter > .wrap_for_news_letter > .news_letter_box {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .news_letter > .wrap_for_news_letter > .news_letter_box > .input_box_name,
  .news_letter > .wrap_for_news_letter > .news_letter_box > .input_box_email {
    width: 100%;
    margin-bottom: 1rem;
  }

  .news_letter > .wrap_for_news_letter > .news_letter_box > .submit_button {
    width: 100%;
    max-width: 300px;
  }
}

.sec6 {
  padding: 2rem 1rem;
  margin: 0 auto;
}
