/** @format */

.seller_leader_info p,
.seller_leader_info select {
  color: var(--Dark-Grey---Paragraph-Text, black);
  font-family: Roboto;
  font-size: 0.73rem;
  font-weight: 600;
  background-color: #f3f3f3;
}
.seller_selector select
{
  border-radius: 0.5rem;
  background: var(--Active-Black, white);
  height: fit-content;
  padding: 0.63rem;
  outline: none;
  border: none;
  margin: auto;
  margin-top: 0.3rem;
}
.seller_leader_info {
  padding-right: 0.75rem;
  align-items: start;
  background-color: #f3f3f3;

}
.seller_leader_info p {
  padding: 0.94rem;
}

.seller_leader_info .wrapper {
  margin: auto;
}

.seller_selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader styles */
.loader {
  border: 2px solid #f3f3f3; /* Light gray border */
  border-top: 2px solid #3498db; /* Blue border for the top */
  border-radius: 50%; /* Make it round */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Apply the spin animation */
}

/* Keyframe animation for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.seller_preview_container {
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
