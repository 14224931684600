.seller_container{
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, white);
  padding: 0.94rem;
  padding-top: 0;
  margin-top: 1rem;
  position: relative;
  overflow: auto;
  /* overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 #f1f1f1; */
  flex: 1;
}
.seller_leader_header,
.seller_leader_info
{   
    padding-right: .75rem;
    display: grid;
    grid-template-columns: .7fr .7fr .7fr .7fr .4fr .7fr;
}

.seller_leader_header{
  position: sticky;
  top: 0;
  left: 0;
  height: 2rem;
  background: var(--Element-BG-Dark-Grey, white);
  padding-top: 0.94rem;
}

.seller_info_list{
  margin-top: 1rem;
}

.seller_container::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.seller_container::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.seller_container::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

.heading.bar {
  padding-left: 0.75rem;
  border-left: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
  color: var(--Dark-Grey---Paragraph-Text, black);

}
.center{
    text-align: center;
}