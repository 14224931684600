.altman-properties-item {
  padding: 2px;
  opacity: 0;
  transform: translateX(-100%); /* Start position off-screen to the left */
  animation: slideIn 1s forwards; /* Apply the animation */
}

/* Define the keyframes for the sliding animation */
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0); /* Move to the final position */
  }
}

.altman-properties-item .sub-main {
  position: relative;
  display: block;
  transform: translateZ(0);
}

.altman-properties-item .sub-main::before,
.altman-properties-item .sub-main::after {
  content: "";
  position: absolute;
  left: 0;
  pointer-events: none;
  z-index: 2;
}

.altman-properties-item .sub-main::before {
  bottom: 18px;
  width: 5px;
  height: 70px;
  background-color: #cbaa45;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.altman-properties-item .sub-main:hover:before {
  bottom: 0;
  height: 100%;
}

.altman-properties-item .sub-main::after {
  top: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #cbaa45;
  opacity: 0;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.altman-properties-item .sub-main:hover:after {
  opacity: 1;
}

.altman-properties-item-inner-wrapper {
  position: relative;
  overflow: hidden;
}

.altman-properties-item-photo {
  position: relative;
  z-index: 1;
}

.altman-properties-item-photo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, transparent, #000);
  /* background: linear-gradient(to bottom, #000, transparent, transparent, #000); */
  opacity: 0.9;
  pointer-events: none;
}

.altman-properties-item .sub-main:hover .altman-properties-item-photo::before {
  background: #000;
  opacity: 0.6;
}

.altman-properties-item-photo canvas {
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.altman-properties-item-content-status-city {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 1;
  opacity: 0;
}

.altman-properties-item
  .sub-main:hover
  .altman-properties-item-content-status-city {
  opacity: 1;
}

.altman-properties-item-content-status-city div {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  padding: 0 0.75vw;
  line-height: 1;
}

.altman-properties-item-content-status-city div:first-child {
  padding-left: 0;
}

.altman-properties-item-content-status-city div:first-child:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 27px;
  background-color: #cbaa45;
  pointer-events: none;
}

.altman-properties-item-content {
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: -67px;
  padding: 0 0 26px 18px;
}

.altman-properties-item-content-address h3 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.altman-properties-item-content-address div {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}

.altman-properties-item-content-features {
  margin-bottom: 5px;
  opacity: 0;
}

.altman-properties-item-content-features li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  color: #fff;
  line-height: 1.8;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 9px;
}

.altman-properties-item-content-features li:first-child {
  padding-left: 0;
}

.altman-properties-item-content-features li::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 14px;
  background-color: #fff;
  pointer-events: none;
}

.altman-properties-item-content-features li:last-child:after {
  opacity: 0;
}

.altman-properties-item-content-price {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #cbaa45;
  opacity: 0;
}

.altman-properties-item-link-label {
  position: absolute;
  bottom: 18px;
  right: 18px;
  font-size: 14px;
  width: 120px;
  padding: 10px 0;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  background-color: #cbaa45;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.altman-properties-item
  .sub-main:hover
  .altman-properties-item-content-features,
.altman-properties-item .sub-main:hover .altman-properties-item-link-label,
.altman-properties-item .sub-main:hover .altman-properties-item-content-price {
  opacity: 1;
}

.altman-properties-item .sub-main:hover .altman-properties-item-content {
  bottom: 0;
}

.ease {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev:before {
  /* content: '→'; */
  left: 23px;
  /* position: absolute; */
  color: #fff;
  padding: 30px 10px;
  background-color: #000000;
  margin-left: 20px;
}

.slick-next:before {
  /* content: '→'; */
  right: 23px;
  /* position: absolute; */
  color: #fff;
  padding: 30px 10px;
  background-color: #000000;
  margin-left: -60px;
}
