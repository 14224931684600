/** @format */

.crm_Intigration_container {
  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #0061df #f1f1f1;
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.94rem;
  flex: 1;
}
.crm_Intigration_container::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.crm_Intigration_container::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.crm_Intigration_container::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
