/** @format */

.crm_edit_container {
  border-radius: 1.5625rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 70rem;
  padding: 1.56rem;
}

.edit_header p {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}

.cancle {
  border-radius: 0.5rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
  padding: 0.625rem 1.25rem;
  margin-right: 1rem;
  cursor: pointer;
  flex: 1;
}
.cancle p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  text-align: center;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
}

.save {
  padding: 0.625rem 1.25rem;
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
  flex: 1;
}

.save p {
  text-align: center;
  color: var(--Black, #13131a);
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
}

.crm_input {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: var(--Light-Grey---Input-Text, #d9d9d9);
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  border: none;
  padding: 0.5rem;
  width: 100%;
}

.crm_information {
  min-width: 18.2rem;
}
.mls_number .mls_form {
  display: flex;
}
.mls_number .mls_form .fetch_property_button {
  flex: 0.3;
  color: var(--Dark-Grey---Paragraph-Text, #999999);
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
}
.mls_number .mls_form .crm_input {
  flex: 0.7;
}
.mls_number p,
.name p,
.address p,
.price p,
.beds p,
.baths p,
.garages p,
.house_type p,
.square_footage p,
.crm_description p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-bottom: 0.35rem;
}

.crm_textarea {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: var(--Light-Grey---Input-Text, #d9d9d9);
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  border: none;
  padding: 0.5rem;
  resize: none;
  height: calc(100% - 1.56rem);
  min-width: 18rem;
}

.main_info_container {
  margin-top: 2rem;
}

.crm_container_slider {
  width: 27rem;
}
/* .crm_container_slider .slick-slider{
  height: 100%;
} */
.crm_container_slider .slick-prev {
  left: 13px;
  z-index: 30;
}
.crm_container_slider .slick-next {
  right: 13px;
  z-index: 30;
}
.crm_container_slider .slick-donts {
  bottom: 13px;
  z-index: 30;
}
.slider_home {
  border-radius: 0.9375rem;
  overflow: hidden;
  position: relative;
  height: 25rem;
}
.slider_home img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.svg_delete {
  position: absolute;
  width: 2rem;
  top: 1%;
  right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  padding: 0.38rem;
  background-color: #18181a;
  border-radius: 50%;
}

.svg_delete img {
  width: 100%;
  height: 100%;
}
.crm_image_add {
  width: 6.49038rem;
  height: 6.25rem;
  border-radius: 0.9375rem;
  background: var(--Active-Black, #232325);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.peginate {
  display: flex;
  /* justify-content: center; */

  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  flex: 1;
  height: 6rem;
  overflow-y: scroll;
  gap: 0.25rem;
}
.peginate::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.peginate::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.peginate::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
.pagination-item {
  display: inline;
}
.pagination-item {
  cursor: pointer;
}
.peginate img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 0.9375rem;
}
.crm_image_add p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  text-align: center;
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.875rem; /* 300% */
  text-decoration-line: underline;
  text-transform: capitalize;
}

.custom_page_nevigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
}

.slider_button {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  cursor: pointer;
}
.slider_button.prev {
  position: absolute;
  bottom: 5%;
  left: 3%;
  z-index: 5;
}

.slider_button.next {
  position: absolute;
  bottom: 5%;
  right: 3%;
}

.action_button {
  display: flex;
  gap: 1rem;
  align-items: center;
}
