.template1 {
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family:  Tenor Sans, sans-serif;
}


.template1  .navbar{
    background-color: var(--primary) !important;
    border-radius: 0px !important;
}

.template1  .navbar-nav{
    margin-left: auto;
}

.template1  .navbar-brand{
    color: var(--secondary);
}

.template1  .nav-link{
    color: var(--secondary);
    display:flex;
    align-items:center;
}

.template1 .dropdown-toggle {
 color: #fff !important;
}

.template1  .navbar-brand > img{
    max-height: 40px;
    padding: 5px;
}

.template1 .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;charset=UTF8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(173, 173, 173, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>') !important;
  }

.template1 .navbar .container{
    display: flow;
}

.template1 .navbar-toggler{
    float: right
}

.template1 .navbar-collapse{
    width: 100%;
    text-align: center;
    color: var(--secondary);
}
  

  
