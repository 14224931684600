.footer {
  background-color: #222;
  /* Dark background */
  color: #ddd;
  /* Light text color */

  font-family: Tenor Sans, sans-serif ;
}

.footer a {
  color: #f8f9fa;
  /* Link color */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .logo {
  max-width: 150px;
}

.footer .social-icons{
  padding: 10px;
}

.footer .social-icons a {
  color: #fff;
  font-size: 1rem;
  margin: 0 10px;
  transition: color 0.3s;
}