.realty-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease; /* Optional: Smooth transition on card hover */
  }
  .carousel-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease; /* Smooth transition for the scale effect */
  }
  /* Hover Effect: Scale image on hover */
  .realty-card:hover .carousel-item img {
    transform: scale(1.1); /* Scale up by 10% on hover */
  }
  .realty-info {
    padding: 15px;
  }
  .realty-info p {
    margin: 0;
  }
  .realty-features {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .realty-price {
    font-size: 18px;
    font-weight: bold;
    color: #212529;
  }