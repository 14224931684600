/** @format */

.Button_react_Tour {
  border-radius: 8px;
  padding-block: 0.45rem;
  padding-inline: 1rem;
  outline: none;
  border: none;
  background-color: white;
  font-family: Roboto;
  font-weight: 400;
  line-height: 21.09px;
  text-align: center;
  font-size: clamp(0.875rem, 1.125vw, 1.125rem);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: all 0.5s;
  outline: 1px solid #fafafc1a;
}
.Button_react_Tour:hover {
  outline: 1px solid #d9d9d9;
}

.Button_react_Tour_close {
  background-color: transparent;
  border: none;
}

.spinner_container {
  height: 100vh;
}
