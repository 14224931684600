.blog_img{
    height: 70vh;
    aspect-ratio: 1/1;
}
.story {
    color: #001a72;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 20px;
}
.storyDis {
    color: #000000;
    font-family: Montserrat, sans-serif;
}