/** @format */

.crm_setting_container {
  gap: 1.5rem;
  margin-top: 2rem;
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.94rem;
  position: relative;
  flex: 1;
}

.crm_setting_container h1 {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

.crm_setting_container p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: capitalize;
}

.info_container {
  margin-top: 2.5rem;
  max-width: 43rem;
  padding: 1.88rem;
  border-radius: 0.9375rem;
  background: var(--Active-Black, #232325);
}

.info_container h1 {
  margin-bottom: 1.5rem;
}
.key {
  padding: 0.63rem;
  border-radius: 0.5rem;
  border: 2px solid var(--Darker-Grey---Fields-BG, #474747);
  background: var(--Element-BG-Dark-Grey, #18181a);
}
.key_input {
  outline: none;
  border: none;
  background: #18181a;
  width: 15rem;
}

/* button_toggle */
.crm_setting_container .toggle-btn {
  width: 3.8125rem;
  height: 2rem;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 6.25rem;
  border: 2.667px solid var(--Darker-Grey---Fields-BG, #474747);
  background: var(--Active-Black, #232325);
}

.toggle-btn:hover {
  border: 2.667px solid var(--Dark-Grey---Paragraph-Text, #999);
  background: var(--Darker-Grey---Fields-BG, #474747);
  transition: all 0.1s ease-in-out;
}

.toggle-btn:hover .inner-circle {
  background-color: #999;
  transition: all 0.1s ease-in-out;
}

.inner-circle {
  width: 1.625rem;
  height: 1.525rem;
  background-color: #474747;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out;
}

.toggle-btn.active {
  border: 2.667px solid #4caf50;
  background: #4caf50;
}

.toggle-btn.active .inner-circle {
  transform: translateX(1.625rem);
  background-color: white;
}
