

.main_container {
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.94rem;
  border-radius: 0.9375rem;
  margin-top: 2rem;
  overflow-y: scroll;
  flex: 1;
}
.main_container::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.main_container::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.main_container::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

.up_section {
  display: grid;
  grid-template-columns: 42rem 45rem;
}
.currentplan_container {
  padding: 0.94rem;
  border-radius: 0.9375rem;
}
.billing_history {
  padding: 0.94rem;
  border-radius: 0.9375rem;
}
.currentPlan {
  padding: 1.88rem;
  border-radius: 0.9375rem;
  background: var(--Active-Black, #232325);
}
.plan .wrapper {
  width: 3.75rem;
  height: 3.75rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
}

.currentPlan .bar {
  border-right: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
}

.planDetails {
  margin-bottom: 2rem;
}

.list .points {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  align-items: baseline;
}
p.heading {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  display: inline;
  margin-left: 10px;
}

.heading_second {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 400;
}

.lower_case{
  text-transform: lowercase;
}

.heading_detail {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0px;
}

.list {
  margin-top: 1rem;
}

.points p {
  color: var(--White, #fafafc);
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}

.points span {
  color: var(--Darker-Grey---Fields-BG, #474747);
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}

/* billing_list */

.billing_container {
  padding: 1.88rem;
  border-radius: 0.9375rem;
  background: var(--Active-Black, #232325);
  height: 100%;
  /* overflow: hidden; */
}

.billing_history_nav select {
  background: #232325;
  outline: none;
  border: none;
}

.billing_list_container {
  height: 12.3rem;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #0061df #f1f1f1;
  padding-right: 0.75rem;
  margin-top: 1rem;
}

.billing_list_container::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.billing_list_container::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.billing_list_container::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

/* down_section */

.down_section {
  padding: 0.94rem;
}

.plan_name .wrapper {
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
}

.Card {
  padding: 1.88rem;
  border-radius: 0.9375rem;
  background: var(--Active-Black, #232325);
  width: 100%;
  margin-top: 2rem;
}

.Card .bar {
  margin: 1rem 0;
  border-bottom: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
}

.Card .price p {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 700;
}
.Card .list {
  margin-top: 1rem;
}

.Card button {
  border-radius: 0.75rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
  border: none;
  width: 100%;
  padding: 0.94rem;
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 700;
}

.Card button img {
  margin-left: 0.5rem;
}

.block {
  display: block;
}
.up {
  align-items: start;
}

.image_section {
  margin-top: 2rem;
  position: relative;
  min-width: 50%;
}

.image_section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.9375rem;
}

.content {
  position: absolute;
  top: 40%;
  right: 5%;
  text-align: right;
}
.content img {
  width: 1.875rem;
  height: 1.875rem;
  object-fit: cover;
}

.content h1 {
  color: #fff;
  text-align: right;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
}
.content p {
  color: var(--White, #fafafc);
  text-align: right;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
}
.grid-pricing-layout{
  display: grid !important;
  grid-template-columns: 1fr 1fr 2fr !important;
  grid-column-gap: 30px !important;
}
.pointwrapper > p{
  margin-bottom: 0px;
}
.pointwrapper{
  margin-bottom:  10px;
}

.float-right{
  float: right;
}

.billing_history_nav > p{
  margin-left: 0px;
}