/** @format */

.edit_container {
  border-radius: 1.5625rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  width: 66.75rem;
  padding: 1.56rem;
  max-height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.edit_container::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.edit_container::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.edit_container::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
.edit_header {
  padding-bottom: 1rem;
}
.edit_action {
  display: flex;
  gap: 0.25rem;
}
.edit_header p {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}

.cancle {
  border-radius: 0.5rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
  padding: 0.5rem 0.625rem;
  margin-right: 1rem;
  cursor: pointer;
}
.cancle p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  text-align: center;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
}

.save {
  padding: 0.625rem 1.25rem;
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
}

.save p {
  text-align: center;
  color: var(--Black, #13131a);
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
}
.title_info {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  gap: 3rem;
  width: 100%;
}
.title_info .image_container {
  min-height:30rem;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
}

.title_info .image_full_container {
  width: 100%;
  height: 100%;
}

.title_info .title_block {
  width: 100%;
}
.info {
  padding: 0.625rem;
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  border: none;
  outline: none;
  width: 100%;
  color: var(--Light-Grey---Input-Text, #d9d9d9);
}
.title_description p,
.title_info p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
}
.wrapper-class {
  padding: 0.625rem;
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  border: none;
  color: white;
}
.toolbar-class {
  background: var(--Active-Black, #232325);
  border: none;
}

.image_container {
  margin: 1rem 0;
  /* height: 35.75rem; */
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  cursor: pointer;
  position: relative;
}
.image_container::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(35, 35, 37, 0.35) 0%,
    rgba(35, 35, 37, 0.35) 100%
  );
}
.upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  text-align: center;
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: 500;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.editor-class {
  max-height: 20rem;
  min-height: 20rem;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.editor-class::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.editor-class::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.editor-class::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

.edit_info {
  /* max-height: 65vh;
  min-height: 35rem; */
  /* overflow-y: scroll; */
  padding-right: 0.35rem;
}
.edit_info::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.edit_info::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.edit_info::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

.rdw-editor-toolbar {
  color: #000000;
}

.rdw-editor-toolbar button {
  color: #000000;
}

.rdw-editor-toolbar button:hover,
.rdw-editor-toolbar button.active {
  color: #000000;
}

.rdw-dropdown-optionwrapper {
  color: #000000;
}

.rdw-dropdown-selectedtext {
  color: #000000;
}

.rdw-editor-main {
  white-space: pre-wrap;
}
