/** @format */

.DashBoard_Header_container {
  background: linear-gradient(
    90deg,
    #0e222e 0%,
    #13131a 30.69%,
    #13131a 61.12%,
    #2e1829 100%
  );

  backdrop-filter: blur(250px);
  min-height: 5rem;
  padding: 0 3rem;
  /* margin-top: 1.5rem; */
}

.DashBoard_Header_container a {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.6875rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.6rem);
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.DashBoard_Header_container a .Logo {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}
.headerButton {
  border-radius: 0.5rem;
  background: rgba(250, 250, 252, 0.05);
  backdrop-filter: blur(5px);
  padding: 0.9375rem 1.25rem;
  gap: 0.625rem;
}

.headerButton p {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 1.125rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.publish-btn:hover {
  background: white;
  color: var(--Dark-Grey---Paragraph-Text, #484848);
}

.publish-btn{
  padding-top: 11px;
}
