.designer-navbar{
    background: linear-gradient(90deg, rgba(8, 8, 8, 1) 0%, rgba(20, 20, 20, 1) 100%) !important;
}

.design-navbar{
    margin-left: auto;
}

.design-publish-btn{
    background-color: rgb(44, 44, 44);
    border-radius: 10%;
}