/* General Styles */
.tile {
    background-color: #18181a;
    border: 2px solid #18181a;
    color: white;
    border-radius: 8px;
    padding: 1.25rem; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 16rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.15rem solid white;
}

.icon {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    flex-shrink: 0; 
}

.tile-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;
    height: 100%;
}

.tile h2 {
    margin: 0;
    font-size: 1rem;
    color: white;
}

.tile p {
    margin: 0;
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

.tile:hover {
    background-color: #fafafa;
    color: #18181a;
    border: 0.15rem solid black;
}

.tile:hover h2, 
.tile:hover p {
    color: #333;
}

.total-lead {
    background-color: #18181a;
    border: 2px solid #18181a;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 20rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
}

.today-lead, .lastmonth-lead {
    background-color: #18181a;
    border: 0.01rem solid white;
    color: white;
    border-radius: 8px;
    padding: 0.5rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 48%; 
    max-width: 50%; 
    height: 18rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.today-lead::-webkit-scrollbar, 
.lastmonth-lead::-webkit-scrollbar {
    width: 1px;
}

.leads {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    border-top: rgba(255, 255, 255, 0.1) solid 0.1rem;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
    .tile {
        padding: 1rem;
    }

    .icon {
        width: 4rem;
        height: 4rem;
    }

    .tile h2 {
        font-size: 0.9rem;
    }

    .tile p {
        font-size: 2.5rem;
    }

    .today-lead, .lastmonth-lead {
        width: 48%;
    }
}

@media screen and (max-width: 768px) {
    .tile {
        padding: 0.8rem;
    }

    .icon {
        width: 3.5rem;
        height: 3.5rem;
    }

    .tile h2 {
        font-size: 0.8rem;
    }

    .tile p {
        font-size: 2.2rem;
    }

    .today-lead, .lastmonth-lead {
        width: 100%;
        margin-bottom: 1rem; 
    }
}

@media screen and (max-width: 480px) {
    .tile {
        padding: 0.6rem;
    }

    .icon {
        width: 3rem;
        height: 3rem;
    }

    .tile h2 {
        font-size: 0.7rem;
    }

    .tile p {
        font-size: 1.8rem;
    }

    .total-lead {
        flex-direction: column; 
        height: auto;
    }
}
