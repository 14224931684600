.exclusive-container
{
  width: min(115rem, 100%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(350px, 1fr));
  /* grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); */
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
  gap: 0.5rem;
  padding: 30px 10px;
  font-family: "Tenor Sans", sans-serif;

}

.exclisveBanner {
  position: relative;
  width: 100%;
  background-image: url("https://images.pexels.com/photos/24706211/pexels-photo-24706211/free-photo-of-gold-on-blue-wall.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-attachment: fixed;
  opacity: 1;
  background-size: cover;
  background-position: center center;
  height: 300px;
}

.excluSection {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
}

.excluSection h1 {
 color: #fff;
 font-family: ' "Tenor Sans", sans-serif';
 font-weight: 800;
}

.excluSection p {
  color: #fff;
  font-family: '"Tenor Sans", sans-serif';

}