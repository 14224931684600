.seller {
  display: flex;
  height:100%;
  gap: .5rem;
  align-items: center;
  background-color: white;
  border-radius: .04rem;
}
 .edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #f0f0f0;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon-svg {
  width: 20px;
  height: 20px;
  fill: #333;
}

.edit-icon:hover .edit-icon-svg {
  fill: #007bff; /* Add hover effect */
}


.seller-info { 
  height:98%;
  margin-top: 1rem;
  margin-left: 1rem;
  width: 20rem;
  background-color: #ffffff;
  border: 1px solid #d0cbcb;
  border-radius: 0.5rem;
  overflow-y: auto; 
  padding: 1rem; 
  display: flex;
  flex-direction: column;
  position: relative;
}

.seller-info::-webkit-scrollbar {
  width: 3px; 
}

.seller-profile {
  width: 3em;
  height: 3em;
  border: .1rem solid #d0cbcb;
  margin-top: 1rem;
  border-radius: 50%;
  margin-left: 1rem;
  flex-shrink: 0; 
}

.seller-name {
  margin-left: 1rem;
}

.seller-email,
.seller-phone,
.seller-address {
  display: flex;
  margin-top: 0.5rem;
  margin-left: 1rem;
  flex-shrink: 0; 
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal; /* Allows the content to wrap as needed */
}

.seller-email-icon,
.seller-phone-icon,
.seller-address-icon {
  width: 24px;  
  height: 24px; 
  margin-right: 0.5rem;
}

.lead-status-dropdown {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
}

.lead-status-dropdown label {
  font-weight: bold;
  margin-bottom: 8px;
  width: 100%;
}

.lead-status-dropdown select {
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
}

.lead-status-dropdown select:focus {
  outline: none;
  border-color: none;
}
.add-notes-seller-container {
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
}

.add-notes-seller-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notes-textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical; 
}

.notes-textarea:focus {
  outline: none;
  border-color: black;                        
}

.save-notes-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
 
.lead-stage-dropdown {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
}

.lead-stage-dropdown label {
  font-weight: bold;
  margin-bottom: 8px;
  width: 100%;
}

.lead-stage-dropdown select {
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
}

.lead-stage-dropdown select:focus {
  outline: none;
  border-color: none;
}
/* second column */

.seller-second-row{
  padding: 1rem;
  width: 70%;
  height: 99%;
  background-color: #ffffff;
  border: 1px solid #d0cbcb;
  border-radius: 0.5rem;
  overflow-y: auto; 
  padding: 1rem; 
  display: flex;
  flex-direction: column;
  position: relative;
}




.seller-leads, .seller-leads-email {
  width: 100%;
  max-height: 100%; /* Allow full height of the container */
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.seller-message {
  width: 100%;
  min-height: fit-content;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
}
.seller-email-content {
  width: 100%;
  min-height: fit-content;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
}
.view-action-plan{
  cursor: pointer;
  color: #007bff;
}
.view-action-plan:hover{
  text-decoration: underline;
}







/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 10rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-bottom: 10px;
}

.modal-content input {
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 20rem;
}

.modal-content button {
  padding: 10px;
  margin-right: 10px;
  background-color: #333;
  color: #ffffff;
  border: .05rem solid white;
  margin-top: .5rem;
}



@media (max-width: 1010px) {
  .seller {
    flex-direction: column; 
    gap: 1rem; /* Increased for better spacing on smaller screens */
  }
  .seller-info {
    margin: 0 auto;
    width: 90%; /* Slightly reduced for better scaling */
    height: auto; /* Allow auto-height for flexibility */
    padding: 1rem; 
  }


  .seller-profile {
    margin: 1rem auto 0 auto;
    width: 10vw; /* Use viewport width for scaling */
    height: 10vw; 
  }

.seller-name,
.seller-email,
.seller-phone,
.seller-address {
  text-align: center; 
}

.lead-status-dropdown,
.add-notes-container {
  width: 95%;
  margin: 1rem auto;
}

.notes-textarea {
  height: 120px; 
}

.seller-leads {
  width: 95%;
  height: auto; 
  margin: 1rem auto;
}

.seller-message,
.seller-note-view {
  width: 95%;
  margin: 1rem auto;
  padding: 1rem;
}

.edit-icon {
  top: 8px;
  right: 8px;
  padding: 4px; 
}

.edit-icon-svg {
  width: 18px;
  height: 18px; 
}
}

@media (max-width: 480px) {
  .seller-info {
    padding: 0.8rem; /* Reduced for better fit on smaller screens */
    width: 95%;
  }

  .seller-profile {
    width: 12vw; /* Scales based on viewport width */
    height: 12vw; 
  }

  .lead-status-dropdown select {
    width: 100%; 
  }

  .save-notes-button {
    width: 100%;
    font-size: 14px; 
    padding: 10px;
  }

  .seller-message,
  .seller-note-view {
    padding: 0.8rem;
    font-size: 14px; 
  }

  .notes-textarea {
    height: 100px; 
  }
}

@media (max-width: 768px) {
  .seller {
    flex-direction: column;
    gap: 2rem;
  }

  .seller-info {
    margin: 0 auto;
    width: 90%;
    height: auto; 
  }

  .seller-profile {
    margin: 0 auto;
    width: 10vw; /* Scalable width */
    height: 10vw; 
  }

  .seller-name,
  .seller-email,
  .seller-phone,
  .seller-address {
    text-align: center; 
  }

  .lead-status-dropdown,
  .add-notes-container {
    width: 90%;
    margin: 1rem auto;
  }

  .notes-textarea {
    height: 120px; 
  }

  .seller-leads {
    width: 90%;
    margin: 1rem auto;
    height: auto;
  }

  .seller-message,
  .seller-note {
    width: 90%;
    height: auto;
  }
}
