/** @format */

.seo_mannager {
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.94rem;
  padding-top: 0;
  margin-top: 1rem;
  position: relative;
  overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 #f1f1f1;
  flex: 1;
}


.seo_mannager_header {
  position: sticky;
  top: 0;
  left: 0;
  height: 2rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding-top: 0.94rem;
}
.seo_mannager_header,
.seo_listting_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.4fr;
  align-items: center;
}
.seo_mannager_header .heading {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

.heading.bar {
  padding-left: 0.75rem;
  border-left: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
}
.buyers_info_list {
  margin-top: 1rem;
}
.seo_mannager::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.seo_mannager::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.seo_mannager::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
.seo_mannager_list {
  margin-top: 1rem;
}

.seo_listting_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.4fr; 
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #474747;
}

.row_data {
  color: white;
  font-size: 0.875rem;
  padding-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.row_data.bar {
  padding-left: 0.75rem;
  border-left: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
}

.no_data {
  color: #999;
  text-align: center;
  padding: 1rem;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center; 
  align-items: center; 
  z-index: 1000;
}

.modal-content {
  background: #18181a;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  width: 400px;
  max-width: 90%; 
  text-align: left; 
  position: relative; 
}

.modal-content h2 {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.modal-content .modal-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; 
}

.input_box_seo {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #444;
  background-color: #232325;
  color: #fff;
  outline: none;
}

.save_button,
.close_button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.save_button:hover {
  background-color: #0056b3;
}

.close_button {
  background-color: #444;
}

.close_button:hover {
  background-color: #555;
}

.notification {
  color: #f39c12;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}

.view-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease; 
}

.view-modal-content {
  background: #1e1e22; 
  color: white; 
  padding: 2rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  width: 450px;
  max-width: 90%;
  text-align: left;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease; 
}

.view-modal-content h3 {
  color: #fff;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.view-modal-content p {
  color: #ccc;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.view-modal-content p strong {
  color: #fff; 
  font-weight: bold;
}

.view-modal-content button {
  background-color: #ff6347; 
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 1.5rem;
  display: block;
  width: 100%;
  text-align: center;
}

.view-modal-content button:hover {
  background-color: #d94c36; 
}

.view-modal-overlay.hidden {
  opacity: 0;
  pointer-events: none;
}

.view-input-box-seo {
  padding: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #444;
  background-color: #232325;
  color: white;
  outline: none;
  font-size: 1rem;
  margin-bottom: 1.25rem;
}

.view-save-button,
.view-close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
  width: 100%;
}

.view-save-button:hover {
  background-color: #0056b3;
}

.view-close-button {
  background-color: #444;
}

.view-close-button:hover {
  background-color: #555;
}

.view-notification {
  color: #f39c12;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}
