/**
 * body {
 *     background-color: #f5f5f5 !important;
 * }
 *
 * @format
 */

.template1 .pageText > p {
  color: rgb(33, 33, 33);
}

.template1 .navbar-nav {
  color: rgb(33, 33, 33);
}

.template1 .sec1 {
  align-items: end;
  display: grid;
  width: 100%;
  height: 95vh;
  min-height: 56vw;
  overflow-x: hidden;
}

.template1 .sec1 > .container {
  width: 100%;
  height: fit-content;
  margin-left: 5rem;
}

.template1 .sec1 > .container > h2 {
  color: black;
  font-weight: bold;
  font-family: Tenor Sans, sans-serif;
}

.template1 .sec1 > .container > p {
  margin-top: 2rem;
  color: var(--secondary);
  margin-bottom: 2rem;
  width: 50%;
  font-size: 16px;
  margin-top: 0;
}

.template1 #sec4 .btn {
  background-color: transparent;
  color: black;
  border: 2px solid black;
  border-radius: 0;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 500;
}

.template1 .sec1 > .container > .btn {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 0;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 500;
}

.template1 .sec2pg2 img {
  width: 100%;
  margin-top: 0px;
}

.template1 .sec2pg2 .row {
  margin-top: 5rem;
  padding-top: 0px;
  background-color: var(--primary);
  margin-left: 5rem;
}

.template1 .sec2pg2 .row p {
  width: min(549px, 100%);
  margin-top: 2rem;
}
.template1 .sec2pg2 .row h2 {
  margin-top: 4rem;
}

.template1 .sec2pg2 .col-md-6 {
  justify-content: start;
  align-content: start;
}

.template1 .sec2pg2 .col-md-6 img {
  margin-left: -1rem;
}

@media (max-width: 768px) {
  .template1 .sec2pg2 .col-md-6 img {
    margin-left: 0rem;
  }
}

.template1 .sec3pg2 h3 {
  margin-top: 1rem;
}
.template1 .sec3pg2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
}

.template1 .sec3pg2:hover {
  transform: translateY(-10px);
}

.template1 .sec3pg2 .container {
  max-width: 800px;
  width: 100%;
}

.template1 .sec3pg2 h3 {
  margin: 0 auto;
  color: #333;
  transition: color 0.3s ease-in-out;
}

.template1 .sec3pg2 h3:hover {
  color: #ff5722;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}


.template1 .sec5pg2 .col-md-6 {
  background-size: cover;
}
.template1 .sec5pg2 .row {
  width: 110%;
  padding-bottom: 5rem;
}

.template1 .sec5pg2 .img2 {
  margin-top: 10rem;
  margin-left: -3rem;
}

.template1 .sec5pg2 .col-md-6 {
  align-content: end;
  padding-bottom: 3rem;
}

.template1 .sec5pg2 .col-md-6 h2 {
  font-size: 40px;
  text-transform: uppercase;
}

.template1 .sec6pg2 {
  padding-top: 6rem;
  padding-left: 6rem;
}

.template1 .sec6pg2 h2 {
  width: 30%;
  font-size: 40px;
}

.template1 .sec7pg2 h2 {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: 4rem;
  text-align: end;
  text-transform: uppercase;
}

.template1 .sec7pg2 .col-md-4 {
  padding: 2rem;
}

.template1 .sec7pg2 .row {
  width: 90%;
  margin: auto;
  margin-top: -10rem;
}

.template1 .sec7pg2 .col-md-4 p {
  width: 80%;
}

@media (max-width: 768px) {
  .sec2pg2 .col-md-6 img {
    height: auto !important;
    width: 100%;
    margin: auto;
  }

  .sec2pg2 .row {
    width: 100%;
    margin: 0px !important;
    padding: 0px;
  }

  .template1 .sec2pg2 h2 {
    text-align: center;
  }

  .template1 .sec2pg2 p:nth-child(3) {
    margin-bottom: 3rem;
  }

  .template1 .sec3pg2 h3 {
    text-align: center;
  }

  .template1 .sec5pg2 .img2 {
    margin-left: 0px;
    margin-top: 0px;
  }

  .template1 .sec5pg2 h2 {
    font-size: 2rem in !important;
  }

  .template1 .sec6pg2 h2 {
    width: 100%;
  }

  .template1 .sec5pg2 .row {
    margin-top: 0px;
  }

  .template1 .sec7pg2 h2 {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 10rem;
  }

  .template1 .sec7pg2 .col-md-4 p {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .template1 .sec7pg2 .col-md-4 img {
    text-align: center;
    margin: auto;
    width: 30%;
  }
}

.template1 .sec5pg2 {
  display: none;
}

.our_team {
  width: min(90rem, 95%);
  margin: 0 auto;
  margin-top: 3rem;
  .heading {
    font-family: Syncopate;
    font-size: 48px;
    font-weight: 400;
    line-height: 49.97px;
    text-align: right;
    color: black;
  }
  .sub-heading {
    font-family: Garamond;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    text-align: right;
    color: black;
  }
}
.hero_button1 {
  padding: clamp(0.5rem, 1vw, 1rem);
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 700;
  font-size: clamp(0.75rem, 1vw, 1.125rem);
  text-transform: uppercase;
  background-color: transparent;
  border: 3px solid white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: white;
}


.hero_button1:hover {
  background-color: #333;
  color: #fff;
}

.hero_button1:focus {
  outline: none;
  /* box-shadow: 0 0 0 3px rgba(0, 0, 255, 0.3); */
}

@media screen and (max-width: 771px) {
  .section4_about_us > .sec3_left_section {
    position: static !important;
  }
  .section4_about_us > .sec3_left_section h2,
  p {
    /* color: black; */
  }
}

.template1 .sec1 > .info_container {
  position: absolute;
  bottom: 10%;
  left: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
}

.template1 .sec1 > .info_container > a {
  border: 2px solid #ffffff;
  font-family: Syncopate;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 20.82px;
  text-align: center;
  background: transparent;
  border-radius: 0;
}
.template1 .sec1 > .hero_section_image {
  display: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 776px) {
  .template1 .sec1 {
    /* background-image: none !important; */
  }
  .template1 .sec1 > .info_container {
    /* position: static; */
    /* top: 0px; */
    p * {
      font-family: Garamond;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: white;
    }
  }
  .template1 .sec1 > .hero_section_image {
    display: block;
  }
}
