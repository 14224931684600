/** @format */

.seller_preview {
  border-radius: 1.5625rem;
  background: #ffffff; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  padding: 1.86rem 0.89rem;
}

.seller_preview_header_info {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.35rem;
}

.seller_preview_header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 0 1rem;
}

.seller_preview_closer {
  padding: 0.5rem;
  border-radius: 50%; 
  background: #f0f0f0; 
  cursor: pointer;
  transition: background 0.3s ease;
}

.seller_preview_closer:hover {
  background: #e0e0e0; 
}

.seller_preview_closer svg {
  width: 14px;
  height: 14px;
  object-fit: cover;
  fill: #333333; 
}

.seller_preview_header_info h1 {
  color: #333333; 
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}

.seller_preview_header_info p {
  color: #777777; 
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}

.seller_details .head {
  color: #555555; 
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.seller_details p {
  color: #000000; 
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
}

.seller_preview_info {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}