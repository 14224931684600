p {
  margin: 0;
}
.dash_board {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.DashBoard_Container {
  background: var(--Black, #13131a);
  padding: 1.87rem;
  flex: 1;
  height: calc(100vh - 6.85rem);
  overflow: auto;
}
.adminsidebar {
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 1.5rem;
  /* flex: 0.2; */
  flex: 0 0 20%; 
  overflow: auto; 
  height: 100%; 
}

.adminsidebar p {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin: 0;
}
.categoryelement {
  padding: 1rem;
  text-decoration: none;
  margin-bottom: 0.35rem;
}

.categoryelement_active {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
}

.dashboardbar {
  height: 0.15rem;
  background: var(--Active-Black, #232325);
  margin-bottom: 0.35rem;
}

.categoryelement:hover {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
}
.Editor {
  position: relative;
  padding: 0.625rem;
  border-radius: 0.5rem;
  background-color: #232325;
  text-decoration: none;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.Editor:hover {
  background: white;
}
.Editor p {
  width: fit-content;
  background: linear-gradient(to right, #2596de -21.08%, #bc319d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.View {
  flex: 1;
}

/* for view use this to fit screen */
.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
nav {
  cursor: pointer;
}
