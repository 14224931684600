/** @format */
p {
  margin: 0;
  margin-bottom: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  word-break: normal;
  word-wrap: break-word;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

body::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

body::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
.design-navbar > li > a {
  background-color: #1f1f20;
  border-radius: 8px;
  opacity: 0px;
  color: #fafafc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.reactour__popover {
  font-family: Montserrat;
}
.reactour__popover span {
  font-family: Roboto !important;
}
#loadingScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
