/** @format */

nav {
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.875rem 1.25rem;
}
nav .header {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.225rem;
  text-transform: uppercase;
  margin: 0;
}
.sortContainer select {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: white;
  padding: 0.5rem 0.3125rem 0.5rem 0.625rem;
  border: none;
  outline: none;
}

.search {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  padding: 0.5rem;
}
.search_input {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: white;
  outline: none;
  border: none;
}

.editor {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.create {
  cursor: pointer;
  padding: 0.5rem 0.63rem;
  border-radius: 0.5rem;
}

.create:hover {
  background: var(--Active-Black, #232325);
}

.sub_texts {
  margin: 0;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.8788rem;
  letter-spacing: 0.2em;
  text-align: left;
  color: #fafafc
}