/** @format */

.buyers_leader_info p,
.buyers_leader_info select {
  color: var(--Dark-Grey---Paragraph-Text, black);
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: #f3f3f3;
}
.buyers_leader_info {
  padding-right: 0.75rem;
  background-color: #f3f3f3;
}
.buyers_leader_info p {
  padding: 0.94rem;
}

.buyer_selector {
  padding: 0.94rem;
}

.buyer_selector select {
  border-radius: 0.5rem;
  width: 100%;
  background: var(--Active-Black, white);
  height: fit-content;
  padding: 0.63rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.buyer_selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader styles */
.loader {
  border: 2px solid #f3f3f3; /* Light gray border */
  border-top: 2px solid #3498db; /* Blue border for the top */
  border-radius: 50%; /* Make it round */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Apply the spin animation */
}

/* Keyframe animation for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buyer_view_svg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
