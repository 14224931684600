/** @format */

nav {
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.875rem 1.25rem;
}
nav .header {
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.225rem;
  text-transform: uppercase;
  margin: 0;
}
.sortContainer select {
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: white;
  padding: 0.5rem 0.3125rem 0.5rem 0.625rem;
  border: none;
  outline: none;
}
.sub_texts {
  margin: 0;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.8788rem;
  letter-spacing: 0.2em;
  text-align: left;
  color: #fafafc
}
.modal-overlay-seller {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-seller {
background-color:rgba(0, 0, 0, 0.90) ; 
 border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: auto;
  margin-top: 5rem;
}

.modal-seller h3 {
  margin-bottom: 20px;
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
}

.modal-seller input,
.modal-seller textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  color: black;
  font-size: 0.875rem;
}

.modal-seller textarea {
  resize: none;
  height: 80px;
}

.modal-seller-actions {
  display: flex;
  justify-content: space-between;
}

.modal-seller-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
}

.modal-seller-actions button[type='button'] {
  background: #444;
  color: #fafafc;
}

.modal-seller-actions button[type='button']:hover {
  background: #555;
}

.modal-seller-actions button[type='submit'] {
  background: #007bff;
  color: white;
}

.modal-seller-actions button[type='submit']:hover {
  background: #0056b3;
}

button:hover {
  opacity: 0.9;
}

@media (max-width: 500px) {
  .modal {
    width: 90%;
  }
}
