/** @format */

.preiview_container {
  border-radius: 1.5625rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 50rem;
  max-width: 55rem;
  padding: 1.56rem;
  max-height: 85vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.close_blog {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.5rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.preview_hader {
  width: 100%;
}

.preiview_container nav p {
  color: var(--White, #fafafc);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}

.preiview_container .blog_preview nav {
  background-color: #f5f2ed;
}

.blog_preview {
  border-radius: 0.9375rem;
  background-color: #fff;
  margin: auto;
  text-align: center;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  width: 100%;
}

.blog_preview::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.blog_preview::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.blog_preview::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}

nav .nav_action_dummy p {
  color: #000;
  font-family: Garamond;
  font-size: 0.79531rem;
  font-weight: 400;
  text-transform: uppercase;
}
.blog_content_preview h1 {
  color: #000;
  font-family: Syncopate;
  font-size: 1.59063rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.blog_preivew_content {
  padding: 2.5rem;
}

.blog_preivew_content p {
  color: #000;
  font-family: Garamond;
  font-size: 0.79531rem;
  font-weight: 400;
}
.blog_image_container img {
  width: 100%;
  /* padding: 1rem; */
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
