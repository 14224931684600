/** @format */

.blog_container {
  height: calc(10.6875rem + 1.25rem);
  overflow: hidden;
  border-radius: 0.75rem;
  padding: 1.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.blog_container:hover {
  background-color: #474747;
  outline: 1px solid var(--White, #fafafc);
}
.blog_container h1 {
  color: var(--White, #fafafc);
  font-family: Roboto;
  font-size: 1.125rem;
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-weight: 500;
  text-transform: uppercase;
}
.blog_container p {
  overflow: hidden;
  color: var(--Dark-Grey---Paragraph-Text, #999);
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}
/* .information {
  padding-right: 1rem;
} */
.action {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 1rem;
  border-left: 1px solid #eee;
}
.view,
.edit,
.delete {
  width: 1.85rem;
  height: 1.85rem;
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #232325);
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view:hover {
  background-color: #3c93f7;
}
.view:hover svg path {
  fill: white;
}

.edit:hover {
  background-color: #ffb10a;
  fill: white;
}
.edit:hover svg path {
  fill: white;
}

.delete:hover {
  background-color: #ff5b5b;
  fill: white;
}
.delete:hover svg path {
  fill: white;
}

.preview {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
