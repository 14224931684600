/** @format */

.buyers_leader {
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, white);
  padding: 0.94rem;
  padding-top: 0;
  margin-top: 1rem;
  position: relative;
  overflow: auto;
  /* overflow: scroll; */
  /* scrollbar-width: thin; */
  /* scrollbar-color: #999 #f1f1f1; */
  flex: 1;
}

.buyers_leader_header {
  position: sticky;
  top: 0;
  left: 0;
  height: 2rem;
  background: var(--Element-BG-Dark-Grey, white);
  padding-top: 0.94rem;
}
.buyers_leader_header,
.buyers_leader_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.4fr 1fr;
  align-items: start;
}
.buyers_leader_header .heading {
  color: var(--Dark-Grey---Paragraph-Text, black);
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

.heading.bar {
  padding-left: 0.75rem;
  border-left: 0.0625rem solid var(--Darker-Grey---Fields-BG, #474747);
}
.buyers_info_list {
  margin-top: 1rem;
}
.buyers_leader::-webkit-scrollbar {
  width: 5px;
  max-height: 2px;
}

.buyers_leader::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
}

.buyers_leader::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: var(--Dark-Grey---Paragraph-Text, #999);
}
.tile-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  gap: 10px; 
}

.tile {
  background-color: #f9f9f9;
  border: 2px solid  #18181a;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px; 
}

.tile h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #18181a;
}

.tile p {
  margin: 10px 0 0;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}
